import cn from "classnames"
import React from "react"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import ContentText from "../../freestanding/content/content-text"
import { Analytics } from "../../freestanding/shared-types"
import {
  pb128,
  pb24,
  pb32,
  pb48,
  pb8,
} from "../../freestanding/utils/padding.module.css"
import * as styles from "./feature-content.module.css"

export interface PropTypes {
  id: string
  background?: "dark" | "grey" | "light-grey" | "themed"
  slant?: boolean
  title: string | React.ReactElement
  overline?: string | React.ReactElement
  description: React.ReactElement
  content: React.ReactElement
  button?: ContentCTA
  buttons?: Array<ContentCTA>
  feature?: Feat
  swapColumns?: boolean
}

interface ContentCTA {
  ctaTitle: string
  to: string
  style?:
    | "filled"
    | "outlined"
    | "text"
    | "none"
    | "link"
    | "footer"
    | "navigation"
    | "icon"
    | "link-inline"
  openInNewWindow?: boolean
  iconRight?: React.ReactElement
  className?: string
  analytics?: Analytics
}

interface Feat {
  title: string | React.ReactElement
  description: React.ReactElement
  features: Array<Features>
}

interface Features {
  title: React.ReactElement
  description: string | React.ReactElement
}

const FeatureContent = ({
  id,
  slant,
  background,
  title,
  overline,
  description,
  content,
  button,
  buttons,
  feature,
  swapColumns = false,
}: PropTypes) => {
  const dynamicContent = (
    <Grid
      lg={6}
      md={7}
      sm={12}
      xs={12}
      className={cn(swapColumns ? styles.mobileOrder1 : null)}
    >
      <Container>{content}</Container>
    </Grid>
  )
  const staticContent = (
    <Grid
      className={cn(pb32, swapColumns ? styles.mobileOrder0 : null)}
      lg={id.indexOf("casestudy") != -1 ? 6 : 4}
      md={5}
      sm={12}
      xs={12}
    >
      <p className={cn("font-overline", pb24)}>
        {overline && (
          <>
            <span
              className={cn({
                ["is-themed-primary"]: background !== "themed",
              })}
              aria-hidden
            >
              &gt;{" "}
            </span>
            {overline}
          </>
        )}
      </p>
      <h2 className={cn("font-h1", pb32)}>{title}</h2>
      <p className={cn("font-p-large", pb24)}>{description}</p>
      {button && (
        <Container>
          <Button
            className={cn(button.className && button.className)}
            style={button.style ? button.style : "link"}
            iconRight={button.iconRight}
            openInNewWindow={button.openInNewWindow}
            to={button.to}
            analytics={button.analytics}
          >
            {button.ctaTitle}
          </Button>
        </Container>
      )}
      {buttons && (
        <Container justify={"start"}>
          {buttons.map(
            (
              { ctaTitle, style, to, openInNewWindow, iconRight, className },
              index,
            ) => (
              <Button
                className={cn("button-group", className, styles.button)}
                style={style ? style : "filled"}
                key={index}
                iconRight={iconRight}
                openInNewWindow={openInNewWindow}
                to={to}
              >
                {ctaTitle}
              </Button>
            ),
          )}
        </Container>
      )}
    </Grid>
  )

  return (
    <div
      id={id}
      className={cn(
        styles.featureContent,
        {
          ["background-is-grey"]: background === "grey",
          ["background-is-dark"]: background === "dark",
          ["background-is-light-grey"]: background === "light-grey",
          ["background-is-themed"]: background === "themed",
        },
        { ["is-slanted-top-larger"]: slant === true },
      )}
    >
      <Container className={feature && pb128} fluid alignItems={"center"}>
        {swapColumns ? dynamicContent : staticContent}
        {swapColumns ? staticContent : dynamicContent}
      </Container>
      <Container fluid={true} alignItems={"start"}>
        {feature && (
          <>
            <Grid lg={4} md={3} sm={12} xs={12} className={pb48}>
              <ContentText>
                <h3 className={cn("font-h3", pb32)}>{feature.title}</h3>
                <p className={cn("font-p-large")}>{feature.description}</p>
              </ContentText>
            </Grid>
            <Grid lg={6} md={8} sm={12} xs={12}>
              <Container alignItems={"start"}>
                {feature.features.map((features, index) => (
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className={cn(pb48, styles.featuresContent)}
                    key={index}
                  >
                    <Container flexContainer={"row"} alignItems={"start"}>
                      <h4 className={cn("font-h6", "is-mute-text", pb8)}>
                        <span
                          className={cn("is-themed-primary", "is-monospace")}
                          aria-hidden
                        >
                          &gt;{" "}
                        </span>
                        {features.title}
                      </h4>
                      <p className={cn("font-p-small", pb8)}>
                        {features.description}
                      </p>
                    </Container>
                  </Grid>
                ))}
              </Container>
            </Grid>
          </>
        )}
      </Container>
    </div>
  )
}

export default FeatureContent
